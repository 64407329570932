
html {
    font-size: 16px;
  }
  html,
  body {
      width: 100%;
      height: 100%;
  }
  body{
      font-family: 'Mukta Mahee', sans-serif;
      color: #222;
      line-height: 1.5;
      font-size: 1.125rem;
  }
  body::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute; /* Positions the pseudo-element relative to the body */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    background-size: 100% auto;
    z-index: -1; /* Ensures the overlay is behind the content */
  }
  button, input, optgroup, select, textarea{
      font-family: 'Mukta Mahee', sans-serif;
  }
  .brand-logo{
      width: 180px;
  }
  .hero{
      height: 100vh;
      min-height: 700px;
  }
  .hero h1 span{
      font-size: 3rem;
      font-weight: 700;
  }
  .hero h1 small{
      font-weight: 300;
      display: block;
      font-size: 2.5rem;
  }
  a {
      color: #00c853;
      -webkit-transition: all .35s;
      -moz-transition: all .35s;
      transition: all .35s;
  }
  a:hover,
  a:focus {
      color: #009624 !important;
      outline: 0;
      text-decoration: none !important;
  }
  a.btn:hover,
  a.btn:focus {
      color: #fff !important;
  }
  section{
      padding: 0;
  }
  .card-outline-primary{
      border-color: #00c853;
  }
  .hero .row {
    align-items: center;
  }
  .height-100 {
    height: 100vh;
  }
  .col-fixed{
      padding-bottom: 100px;
      align-items: center;
      display: flex;
      justify-content: center;
  }
  .vMiddle,
  #main-offer-form {
    max-width: 40rem;
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width:576px){
      .col-fixed{
          position: fixed;
          top: 0;
          bottom: 0;
      }
  }
  .form-control{
      height: 3rem;
  }
  textarea.form-control {
    height: 6rem;
  }
  
  .form-control:hover, .form-control:focus{
      border-color: #00c853;
  }
  /*===============================================
  * Background Colors
  ================================================*/
  .bg-alt {
      background-color: #fff;
  }
  .bg-faded{
      background-color: #F2F2F2;
  }
  .bg-blue{
      background-color: #032EFF !important;
  }
  .bg-primary{
      background-color: #00c853 !important;
  }
  .bg-purple{
      background-color: #3d5afe;
  }
  .bg-orange{
      background-color: #ffea00;
  }
  .bg-yellow{
      background-color:#feac00;
  }
  .bg-pink{
      background-color:#ff0080;
  }
  .bg-green{
      background-color:#00c853;
  }
  .bg-red{
      background-color:#FF3B30;
  }
  .bg-violet{
      background-color: #7B1FA2;
  }
  .bg-teal{
      background-color: #00796B;
  }
  .bg-slate{
      background-color: #455A64;
  }
  .bg-indigo{
      background-color: #303F9F;
  }
  /*===============================================
  * Text Colors
  ================================================*/
  .text-faded {
      color: #D9EDF7;
  }
  .text-dark {
      color: #37474F;
  }
  .text-muted{
      color: #999 !important;
  }
  .text-light {
      color: #fff;
  }
  .text-light:hover,.text-light:focus {
      color: #eee;
      text-decoration:none;
  }
  .text-primary {
      color: #00c853 !important;
  }
  .text-purple{
      color: #3d5afe !important;
  }
  .text-orange{
      color: #ffea00 !important;
  }
  .text-yellow{
      color:#feac00 !important;
  }
  .text-pink{
      color:#ff0080 !important;
  }
  .text-green{
      color:#00c853 !important;
  }
  .text-red{
      color:#FF3B30 !important;
  }
  .text-violet{
      color: #7B1FA2 !important;
  }
  .text-teal{
      color: #00796B !important;
  }
  .text-slate{
      color: #455A64 !important;
  }
  .text-indigo{
      color: #303F9F !important;
  }
  /*===============================================
  * Icon Sizes
  ================================================*/
  label{
    margin: 0;
  }
  .action-icons {
    position: absolute;
    bottom: 4rem;
    width: 100%;
    left: 0;
  }
  .icon-lg {
      font-size: 60px;
      line-height: 18px;
  }
  .icon-md {
      font-size: 2.5rem;
      line-height: 14px;
  }
  .icon-sm {
      font-size: 30px;
      line-height: 14px;
  }
  .action-icons .lead {
    font-size: 1rem;
    margin-top: -.5rem;
  }
  .form-control-feedback {
    font-size: .875rem;
  }
  /*===============================================
  * Colored Buttons
  ================================================*/
  .btn {
      font-weight: 700;
      cursor: pointer;
  }
  .btn-outline-white {
      color: #fff !important;
      background-image: none;
      background-color: transparent;
      border-color: #fff;
  }
  .btn-outline-white:hover {
      background: rgba(255, 255, 255, 0.2) !important;
  }
  .btn-white {
      color: #00c853;
      background-color: #fff;
      border-color: #fff;
  }
  .btn-white:hover {
      background: transparent !important;
      color: #fff !important;
  }
  .btn-radius{
      border-radius: 50px;
  }
  .border-none{
      border: none !important;
      border-color: transparent !important;
  }


.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 0.1em;
  font-size: 60px;
  color: rgb(34, 34, 34);
  font-weight: 800;
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

#root {
    height: 100% !important;
}
